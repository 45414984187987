import styled from "styled-components";
import Header from "../header";
import Infobar from "../infobar";
import Navbar from "../navbar";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import MainMenuNavbar from "../mainMenuNavbar";

export const HeaderWrapStyles = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  @media (max-width: 1024px) {
    .mobile-header-row {
      display: flex;
      align-items: center;
      background: #fff;
    }
  }
`;

const HeaderWrap = () => {
  const { width } = useWindowSize();
  return (
    <HeaderWrapStyles>
      {width <= 1024 ? (
        <>
          <div className="mobile-header-row">
            <Header />
          </div>
          <MainMenuNavbar />
          <Navbar />
        </>
      ) : (
        <>
          <Header />
          <Infobar />
          <Navbar />
        </>
      )}
    </HeaderWrapStyles>
  );
};

export default HeaderWrap;
