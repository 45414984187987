import React from "react";
import { LoadingStyles } from "./styles";

const Loading = () => {
  return (
    <LoadingStyles>
      <div className="loadingContainer">
        <div className="spinner"></div>
      </div>
    </LoadingStyles>
  );
};

export default Loading;
