import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingStyles = styled.div`
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    .spinner {
      border: 16px solid #f3f3f3;
      border-top: 16px solid #009963;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: ${spin} 2s linear infinite;
      margin: 0 auto;
    }
  }
`;
