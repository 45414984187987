import React from "react";
import { FooterStyles } from "./styles";

const Footer: React.FC = () => {
  return (
    <FooterStyles>
      <div className="footerContentWrap">
        <div className="logoImage">
          {/* <h1>과일여지도</h1> */}
          <h1>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
              alt="logo"
            />
          </h1>

          <p className="copyright">
            COPYRIGHT©2020 BANGVANG ALL RIGHTS RESERVED.
          </p>
        </div>
        <div className="textBox">
          <p>상호 : 과일여지도</p>
          <p>대표 : 대표명</p>
          <p>주소 : 서울특별시 영등포구 여의도동 112 </p>
          <p>고객센터 : AM 10:00 ~ PM 6:00</p>
        </div>
      </div>
    </FooterStyles>
  );
};

export default Footer;
