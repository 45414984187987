// 과일 여지도 주소
export const headquartersAddress = {
  address: "경기도 고양시 덕양구 내유동 506-1",
};

// 텍스트 메시지 관리
export const messages = {
  welcomeMessage:
    "매일 매일 신선한 국내산 과일과, 수입 과일을 공급하며 CS ZERO를 위해 1개의 상품이라도 꼼꼼한 검수를 통해 포장합니다.",
  OrderReferMessage:
    "저희 과일여지도를 이용해주시는 모든 위탁사 분들 진심으로 감사드립니다! 주문 방식 및 가격 정책은 공지사항을 반드시 참조해시기 바랍니다!",
  ExchangeReturnMessage:
    "반드시 공지사항에 CS규정을 확인하시고 이용해주시기 바랍니다!",
};

// 입금 계좌 정보 관리
export const DepositAccount = {
  bankName: "새마을 금고",
  accountNumber: "9003-2928-1004-1",
  accountHolder: "과일여지도(윤진경)",
  infoText: "입금자명을 회사명 또는 대표자명과 동일하게 해주시기 바랍니다.",
};

// 배송비 안내
export const ShippingCost = {
  Message:
    "제주, 도서산간 지역의 경우 각각 3,000원 / 5,000원의 배송비가 발생됩니다.",
};

// 판매규정
export const salesPolicy = {
  text: `
    1. 공급가 변동은 주 1회 목요일~금요일 사이 다음 주 월요일부터 ~일요일까지 적용되는 공급가를 제공합니다.\n
    2. 입금 방식은 선결제 방식입니다(선결제 후 발주 가능, 입금 확인 안 될 경우 발주 불가).\n
    3. 모든 단가는 배송비가 포함된 가격입니다.\n
    4. 날씨에 따라 아이스팩 및 보온 보냉 팩이 추가되며, 단가에 포함됩니다.\n
    5. 입금 시 사업자명 또는 주문자명으로 입금해 주세요. 계산서 발행이 당월 금액을 익월 10일 전에 발행해드리기 때문에\n
       차후 취합 시 어려움이 발생할 수 있어요. (주문자명: 사업자 상호 또는 스토어명)\n
    6. CS는 CS하기 메뉴에서 신청해 주세요!\n
    7. 발주 마감 시간: 일~목요일 오후 5시\n
       예: 일요일 발주 건은 월요일 출고, 주중 공휴일 및 임시 휴일인 경우 공휴일 및 휴일 다음 날 발송.\n
    8. CS 문의는 오전 8시~오후 5시까지 운영하며, 상시 순차적으로 답변을 드립니다. (가급적 빠르게 응대해 드립니다.)\n
    9. 해당 판매규정을 공지사항 메뉴에서 다시 확인하실 수 있습니다.\n
  `
    .trim()
    .replace(/\n\s+/g, "\n"),
};

// 취소, 환불, 반품 불가 규정
export const refundPolicy = {
  text: `
  1. 포장,출고 이후 고객의 단순변심\n
  2. 고객의 주관적인 사유 \n
  ex : 상세페이지내 사진과 다르다,크기차이,맛이없다. 모양이 일정치않다, 신선하지 않은 듯 하다 등\n
  생과일 특성상 표시되어 있는 중량보다  ±10% 이내 차이가 날수 있습니다.\n
  3. 수령인의 부재, 잘못된 주소, 잘못된 연락처로 인한 배송지연,반송,방치 \n
  4. 수령인의 수취거부\n
  5. 판매자와의 협의없이 임의로 반송하는 경우\n
  6. 여름,겨울에 수령하지 못하고 외부에 오래 방치되어 신선식품이 상한 경우\n
  7. 신선식품의 특성상 하루사이에도 변질의 우려가 있으니 수령 후 바로 상품상태를 확인하시기 바랍니다.\n
  8. 상품 상태에 대한 명확한 판단이 어려운 사유이거나, 상품 수령후 보관중 발생한 문제에 대한 요청\n
  `
    .trim()
    .replace(/\n\s+/g, "\n"),
};
