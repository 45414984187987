import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import ScrollToTop from "./layout/scrollTotop";
import GlobalStyle from "./components/style/global";
import routes from "./routes/PrivateRouter/index";
import Layout from "../src/layout/index";
import Loading from "../src/pages/Loading/index";
import {
  LoginInfoContextProvider,
  useLoginInfoContext,
  ModalChangeContextProvider,
  useModalChangeContext,
  LoginContextProvider,
  useLoginContext,
  AddressContextProvider,
  CheckSystemContextProvider,
  useAddressContext,
  OrderHistoryContextProvider,
  useOrderHistoryContext,
  ProductListContextProvider,
  useProductListContext,
  UserOrdersInfoContextProvider,
  useOrdersInfoContext,
  UserAddressInfoContextProvider,
  useAddressInfoContext,
  BulkOrderModalContextProvider,
  useBulkOrderModalContext,
  ImageModalContextProvider,
  useImageModalContext,
  SenderInfoModalContextProvider,
  useSenderInfoModalContext,
  MobileNavBarContextProvider,
  useMobileNavBarContext,
  useCheckSystemContext,
} from "./context/MyContext";

// Lazy load modals 모달 컴포넌트를 동적으로 로드
const LoginModal = lazy(() => import("./components/modals/login/index"));
const AddressModal = lazy(() => import("./components/modals/address/index"));
const CheckSystemModal = lazy(
  () => import("./components/modals/checkSystem/index")
);
// const OrderHistoryModal = lazy(() => import("./components/modals/order/index"));
const ProductListModal = lazy(
  () => import("./components/modals/productList/index")
);
const BulkOrderModal = lazy(
  () => import("./components/modals/bulkOrder/index")
);
const ImageModal = lazy(() => import("./components/modals/image/index"));
const SenderInfoModal = lazy(
  () => import("./components/modals/senderInfo/index")
);
const checkSystem = lazy(() => import("./components/modals/checkSystem/index"));

const AppContent: React.FC = () => {
  // 로그인 정보
  const [loginInfo, setLoginInfo] = useState(false);

  // 로그인 여부 Context
  const { isLoggedIn, setIsLoggedIn } = useLoginInfoContext();

  const { setIsModalChangeTrue, isModalChangeTrue } = useModalChangeContext(); // 모달창에서 액션이 페이지에 변화를 주는지

  // 로그인 모달 Context 및 useState
  const { isLoginTrue, setIsLoginTrue } = useLoginContext();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  // 시스템 체크 모달 Context 및 useState
  const { isCheckSystemTrue, setIsCheckSystemTrue } = useCheckSystemContext();
  const [isCheckSystemModalOpen, setIsCheckSystemModalOpen] = useState(false);

  // 주소 변경 모달 Context 및 useState
  const { isAddressTrue, setIsAddressTrue } = useAddressContext();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  // 주문 내역 모달 Context 및 useState
  // const { isOrderHistoryTrue, setIsOrderHistoryTrue } =
  //   useOrderHistoryContext();
  // const [isOrderHistoryModalOpen, setIsOrderHistoryModalOpen] = useState(false);

  // 상품 리스트 모달 Context 및 useState
  const { isProductListTrue, setIsProductListTrue } = useProductListContext();
  const [isProductListModalOpen, setIsProductListModalOpen] = useState(false);

  // 대량 주문 모달 Context 및 useState
  const { isBulkOrderModalTrue, setIsBulkOrderModalTrue } =
    useBulkOrderModalContext();
  const [isBulkOrderModalOpen, setIsBulkOrderModalOpen] = useState(false);

  const { isImageModalTrue, setIsImageModalTrue } = useImageModalContext();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const { isSenderInfoModalTrue, setIsSenderInfoModalTrue } =
    useSenderInfoModalContext();

  const navigate = useNavigate();

  const closeModal = () => {
    setIsLoginModalOpen(false);
    setIsLoginTrue(false);
    setIsCheckSystemModalOpen(false);
    setIsCheckSystemTrue(false);
    setIsAddressModalOpen(false);
    setIsAddressTrue(false);
    setIsProductListModalOpen(false);
    setIsProductListTrue(false);
    setIsBulkOrderModalTrue(false);
    setIsBulkOrderModalOpen(false);
    setIsImageModalTrue(false);
    setIsImageModalOpen(false);
    setIsSenderInfoModalTrue(false);
    // setIsOrderHistoryModalOpen(false);
    // setIsOrderHistoryTrue(false);
  };

  // 로그인 정보 유무
  useEffect(() => {
    if (isLoggedIn) {
      setLoginInfo(true);
    } else {
      setLoginInfo(false);
    }
  }, [isLoggedIn]);

  // 첫 렌더링 시 로그인 정보 유무
  useEffect(() => {
    const sessionLoginInfo = window.sessionStorage.getItem("loginInfo"); // 로그인 정보
    const localLoginInfo = window.localStorage.getItem("loginInfo"); // 로그인 정보

    if (sessionLoginInfo) {
      setIsLoggedIn(true);
      setLoginInfo(true);
    } else if (localLoginInfo) {
      setIsLoggedIn(true);
      setLoginInfo(true);
    } else {
      setIsLoggedIn(false);
      setLoginInfo(false);
    }
  }, []);

  useEffect(() => {
    if (isLoginTrue) {
      setIsLoginModalOpen(true);
    }
  }, [isLoginTrue]);

  useEffect(() => {
    if (isAddressTrue) {
      setIsAddressModalOpen(true);
    }
  }, [isAddressTrue]);

  // useEffect(() => {
  //   if (isOrderHistoryTrue) {
  //     setIsOrderHistoryModalOpen(true);
  //   }
  // }, [isOrderHistoryTrue]);

  useEffect(() => {
    if (isProductListTrue) {
      setIsProductListModalOpen(true);
    }
  }, [isProductListTrue]);

  useEffect(() => {
    if (isCheckSystemTrue) {
      setIsCheckSystemModalOpen(true);
    }
  }, [isCheckSystemTrue]);

  useEffect(() => {
    if (isBulkOrderModalTrue) {
      setIsBulkOrderModalOpen(true);
    }
  }, [isBulkOrderModalTrue]);

  useEffect(() => {
    if (isImageModalTrue) {
      setIsImageModalOpen(true);
    }
  }, [isImageModalTrue]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <GlobalStyle />
        {/*로그인 모달 */}
        {!loginInfo && isLoginModalOpen && (
          <LoginModal closeModal={closeModal} navigate={navigate} />
        )}
        {/*시스템 체크 모달 */}
        {isCheckSystemModalOpen && <CheckSystemModal />}

        {/*주소 등록 모달 */}
        {loginInfo && isAddressModalOpen && (
          <AddressModal closeModal={closeModal} />
        )}
        {/* 주문 내역 모달
        {loginInfo && isOrderHistoryModalOpen && (
          <OrderHistoryModal closeModal={closeModal} />
        )} */}
        {/* 상품 리스트 모달 */}
        {loginInfo && isProductListModalOpen && (
          <ProductListModal closeModal={closeModal} />
        )}
        {/* 대량 주문 모달 */}
        {loginInfo && isBulkOrderModalOpen && (
          <BulkOrderModal closeModal={closeModal} />
        )}
        {/* 이미지 모달 */}
        {loginInfo && isImageModalOpen && (
          <ImageModal closeModal={closeModal} />
        )}
        {/* 보내는이 정보 모달 */}
        {loginInfo && isSenderInfoModalTrue && (
          <SenderInfoModal closeModal={closeModal} />
        )}
        {/* Layout 컴포넌트에 로그인 유무 (isLoggedIn)를 props로 전달 */}
        {/* Layout 컴포넌트에서 로그인 유무에 따른 route 분기 처리 진행 */}
        {/* <Router> */}
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<Layout isLoggedIn={loginInfo} />} />
          </Routes>
        </ScrollToTop>
        {/* </Router> */}
      </Suspense>
    </>
  );
};

// ModalChangeContextProvider이 최상단에 위치해야함, 모달들의 API호출 여부에 따라 페이지의 data들을 API를 호출하여 최신으로 업데이트 해주기를 위함
const App: React.FC = () => {
  return (
    <LoginInfoContextProvider>
      <MobileNavBarContextProvider>
        <ModalChangeContextProvider>
          <UserAddressInfoContextProvider>
            <UserOrdersInfoContextProvider>
              <LoginContextProvider>
                <CheckSystemContextProvider>
                  <AddressContextProvider>
                    {/* <OrderHistoryContextProvider> */}
                    <ProductListContextProvider>
                      <BulkOrderModalContextProvider>
                        <ImageModalContextProvider>
                          <SenderInfoModalContextProvider>
                            <Router>
                              <AppContent />
                            </Router>
                          </SenderInfoModalContextProvider>
                        </ImageModalContextProvider>
                      </BulkOrderModalContextProvider>
                    </ProductListContextProvider>
                    {/* </OrderHistoryContextProvider> */}
                  </AddressContextProvider>
                </CheckSystemContextProvider>
              </LoginContextProvider>
            </UserOrdersInfoContextProvider>
          </UserAddressInfoContextProvider>
        </ModalChangeContextProvider>
      </MobileNavBarContextProvider>
    </LoginInfoContextProvider>
  );
};

export default App;
