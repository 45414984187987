import styled from "styled-components";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  useLoginInfoContext,
  useAddressInfoContext,
  useOrdersInfoContext,
  useLoginContext,
  useAddressContext,
} from "../../context/MyContext";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";

export const MainMenuNavbarStyles = styled.div`
  width: 100%;
  background-color: var(--color-background-primary);
  overflow-x: auto;
  color: #fff;
  background: var(--color-logo-primary);
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 10px 12px;
    overflow-x: auto;
    li {
      cursor: pointer;
      /* color: var(--color-label-secondary); */
      font-size: var(--font-size-sm);
      font-weight: 400;
      white-space: nowrap;
      button {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        color: inherit; // 기존 텍스트 색상 유지
        &:hover {
          // 기존 hover 스타일 유지
        }
      }
    }

    .kakaoButton {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: #fee500;
      border: none;
      border-radius: 6px;
      width: 90px;
      height: 30px; /* 높이 30px로 설정 */
      padding: 0px 8px;
      cursor: pointer;
      font-weight: 500;
      color: #000000;
      font-size: 12px;
      transition: all 0.2s ease;

      &:hover {
      }

      .kakaoIcon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
`;

const MainMenuNavbar = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { setIsUserOrdersInfo } = useOrdersInfoContext(); // 유저의 주문하기 페이지 정보 Context API
  const { setIsUserAddressInfo } = useAddressInfoContext(); // 유저의 주소 정보 Context API
  const { isLoggedIn, setIsLoggedIn } = useLoginInfoContext(); // 로그인 여부 Context
  const { setIsLoginTrue } = useLoginContext(); // 로그인 모달 context
  const { setIsAddressTrue } = useAddressContext(); // 주소 변경 모달 context
  const [loginInfo, setLoginInfo] = useState<boolean>(false);

  const handleLoginBtnClick = () => {
    setIsLoginTrue(true);
  };

  const handleAddressBtnClick = () => {
    setIsAddressTrue(true);
  };

  // 로그아웃 버튼 함수
  const handleLogoutBtnClick = () => {
    const localLoginInfo = window.localStorage.getItem("loginInfo");
    const sessionLoginInfo = window.sessionStorage.getItem("loginInfo");

    if (localLoginInfo) {
      window.localStorage.clear(); // 로컬 스토리지 모든 항목 삭제
    } else if (sessionLoginInfo) {
      window.sessionStorage.clear(); // 세션 스토리지 모든 항목 삭제
    }
    setLoginInfo(false); //로그인 정보 초기화
    setIsLoggedIn(false); // 로그인 여부 Context API 초기화
    setIsUserOrdersInfo([]); // 주문하기 페이지 정보 Context API 초기화
    setIsUserAddressInfo([]); // 유저 주소 정보 Context API 초기화
    navigate("/"); // 메인 페이지로 이동
  };

  return (
    <MainMenuNavbarStyles>
      <div className="subMemu">
        {isLoggedIn ? (
          <ul>
            <li
              onClick={() => {
                handleLogoutBtnClick();
              }}
            >
              로그아웃
            </li>
            <li
              onClick={() => {
                navigate("/guide");
              }}
            >
              과일여지도 사용법
            </li>
            <li
              onClick={() => {
                handleAddressBtnClick();
              }}
            >
              정보 변경
            </li>
            {width >= 768 ? (
              <li
                onClick={() => {
                  // navigate("/customer-service");
                  navigate(`/customer-service?refresh=${new Date().getTime()}`);
                }}
              >
                고객센터
              </li>
            ) : null}
            <button
              className="kakaoButton"
              onClick={() =>
                window.open("https://open.kakao.com/o/gGcQb5Hg", "_blank")
              }
              type="button"
            >
              <span className="kakaoIcon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/open-chat.png`}
                  alt="카카오톡 채팅 아이콘"
                />
              </span>
              오픈채팅
            </button>
          </ul>
        ) : (
          <ul>
            <li
              onClick={() => {
                handleLoginBtnClick();
              }}
            >
              로그인
            </li>
            <li
              onClick={() => {
                navigate("/guide");
              }}
            >
              과일여지도 사용법
            </li>
            <li
              onClick={() => {
                navigate("/customer-service");
              }}
            >
              고객센터
            </li>
            <button
              className="kakaoButton"
              onClick={() =>
                window.open("https://open.kakao.com/o/gGcQb5Hg", "_blank")
              }
              type="button"
            >
              <span className="kakaoIcon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/open-chat.png`}
                  alt="카카오톡 채팅 아이콘"
                />
              </span>
              오픈채팅
            </button>
          </ul>
        )}
      </div>
    </MainMenuNavbarStyles>
  );
};

export default MainMenuNavbar;
